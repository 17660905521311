import {
  addSubToNotification,
  ISubjectProps,
  removeSubToNotification,
} from '@NKE/utils'
import React, { useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const ToastHandler = () => {
  useEffect(() => {
    addSubToNotification({
      next: notif => handleNotif(notif),
    })

    return () => {
      removeSubToNotification()
    }
  }, [])

  const handleNotif = (notif: ISubjectProps) => {
    // switch (notif.action) {
    //   case 'success':
    //     toast.success(notif.toastProps.content, notif.toastProps.options)
    //     break;

    //   default:
    //     break;
    // }
    toast[notif.action](
      notif.action === 'update'
        ? notif.toastProps.id
        : notif.toastProps.content,
      notif.toastProps.options
    )
  }

  return (
    <ToastContainer
      position='top-right'
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      draggable
      pauseOnHover
      theme='colored'
    />
  )
}

export default ToastHandler
