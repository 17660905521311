import {
  AppBar,
  Badge,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Popover,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core'
import React, {
  FunctionComponent,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import TranslateIcon from '@material-ui/icons/Translate'
import SettingsIcon from '@material-ui/icons/Settings'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import AccountCircle from '@material-ui/icons/AccountCircle'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import HomeIcon from '@material-ui/icons/Home'
import BugReport from '@material-ui/icons/BugReport'
import ModalReportBug from './modal_report_bug'
import { LanguageSelector } from '../language_selector/language_selector'
import { useUserInfo } from '@nke/spp-components.context.user-info'
import { axiosClient, removeSubToCart } from '@NKE/utils'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import DesktopMacIcon from '@material-ui/icons/DesktopMac'
import { addSubToCart } from '@NKE/utils'
import Dashboard from '../dashboard'
import ToastHandler from './toast-handler'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  title: {
    paddingLeft: theme.spacing(3),
  },
  dashboardContainer: {
    flexGrow: 1,
    marginLeft: -theme.spacing(3),
  },
}))

interface INavbar {
  activedApp: string
}

interface IIcon {
  tooltip: string
  action: () => void
  icon: ReactElement
  Wrapper: FunctionComponent
}

const Navbar = ({ activedApp }: INavbar) => {
  // Commento di prova
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [OpenReportModal, setOpenReportModal] = useState(false)
  const [TotCartItems, setTotCartItems] = useState(null)

  const { t } = useTranslation()

  const userInfo = useUserInfo()

  useEffect(() => {
    addSubToCart({
      next: tot => setTotCartItems(tot ?? null),
    })

    return () => {
      removeSubToCart()
    }
  }, [])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const logout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('i18nextLng')
    axiosClient.defaults.headers.common['Authorization'] = null

    //client.clearStore();
    history.pushState(null, null, '/login')
  }

  const backToHome = () => {
    let _urlHome = '/spp'
    // if (activedApp === "SPP") _urlHome = "/spp";
    history.pushState(null, null, _urlHome)
  }

  const settings = () => {
    history.pushState(null, null, '/settings')
  }

  const historyBack = () => {
    const _state: { from?: '' } = history.state ?? {}
    const _from: string = _state.from ?? ''
    if (_from !== 'login') {
      history.back()
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  let _otherIcons: IIcon[] = []
  if (activedApp === 'SPP') {
    _otherIcons = [
      {
        icon: <ShoppingCartIcon />,
        Wrapper: props => (
          <Badge badgeContent={TotCartItems} color='error' overlap='circle'>
            {props.children}
          </Badge>
        ),
        tooltip: t<string>('cart'),
        action: () => history.pushState(null, null, '/spp/cart'),
      },
    ]
  }

  return (
    <AppBar position='fixed' className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.dashboardContainer}>
          {parseInt(userInfo.type) <= 2 ? (
            <Dashboard />
          ) : (
            <Typography
              component='h1'
              variant='h6'
              color='inherit'
              noWrap
              className={classes.title}
            >
              Spare Parts Platform
            </Typography>
          )}
        </div>
        <Tooltip title={t<string>('goBack')} placement='bottom' arrow>
          <IconButton color='inherit' onClick={historyBack}>
            <ChevronLeftIcon />
          </IconButton>
        </Tooltip>
        {_otherIcons.map(({ icon, Wrapper, tooltip, action }, index) => (
          <Wrapper key={index}>
            <Tooltip title={tooltip} placement='bottom' arrow>
              <IconButton color='inherit' onClick={action}>
                {icon}
              </IconButton>
            </Tooltip>
          </Wrapper>
        ))}
        <Tooltip title={t<string>('backHome')} placement='bottom' arrow>
          <IconButton color='inherit' onClick={backToHome}>
            <HomeIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t<string>('userMenu')} placement='bottom' arrow>
          <IconButton color='inherit' onClick={handleClick}>
            <AccountCircle />
          </IconButton>
        </Tooltip>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <List>
            <ListItem>
              <ListItemText primary={userInfo.email ?? ''} />
            </ListItem>
            {userInfo.type === '1' || userInfo.type === '2' ? (
              <>
                <ListItem button onClick={settings}>
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary={t<string>('settings')} />
                </ListItem>
                {/* <ListItem button onClick={() => setOpenReportModal(true)}>
                  <ListItemIcon>
                    <BugReport />
                  </ListItemIcon>
                  <ListItemText primary={t<string>('reportBug')} />
                </ListItem> */}
              </>
            ) : (
              <></>
            )}
            <ListItem>
              <ListItemIcon>
                <TranslateIcon />
              </ListItemIcon>
              <LanguageSelector />
            </ListItem>
            <ListItem button onClick={logout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary='Logout' />
            </ListItem>
          </List>
        </Popover>
      </Toolbar>
      <ModalReportBug
        open={OpenReportModal}
        onClose={() => setOpenReportModal(false)}
        fullWidth={true}
        maxWidth='md'
      />
      <ToastHandler />
    </AppBar>
  )
}

export default Navbar
