import React, { useEffect, useState } from 'react'

import { getPlatformClient, getUserInfo, IUserInfo, theme } from '@NKE/utils'
import Navbar from './navbar'
import {
  CssBaseline,
  ThemeProvider,
  createGenerateClassName,
  StylesProvider,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { CubeLoader } from './components/loader'
import { UserInfoProvider } from '@nke/spp-components.context.user-info'
import { ApolloProvider } from '@apollo/client'
import './index.scss'

const generateClassName = createGenerateClassName({
  disableGlobal: true,
  seed: 'themeNavbarComponent',
})

export default function Root(props) {
  const [ActivedApp, setActivedApp] = useState('Platform')
  const [Loading, setLoading] = useState(true)
  const { t } = useTranslation()

  useEffect(() => {
    updateActivedApp(window.location.pathname)

    getUserInfo()
      .then(res => {
        if (res) {
          setLoading(false)
        } else {
          window.history.pushState(null, null, '/login')
        }
      })
      .catch(() => window.history.pushState(null, null, '/login'))

    if (window.location.pathname == '/')
      window.history.pushState(null, null, '/spp')
  }, [])

  window.addEventListener('single-spa:before-routing-event', (ev: any) => {
    updateActivedApp(ev.detail.newUrl.replace(window.location.origin, ''))
  })

  const updateActivedApp = (pathname: string) => {
    if (pathname.startsWith('/spp')) setActivedApp('SPP')
    else if (pathname.startsWith('/settings')) setActivedApp('Settings')
    else setActivedApp('Platform')
  }

  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={getPlatformClient()}>
          <UserInfoProvider token={localStorage.getItem('token') ?? ''}>
            <CssBaseline />
            {Loading ? (
              <div
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  height: '100vh',
                  width: '100vw',
                  zIndex: 100,
                  fontSize: '5rem',
                  color: 'grey',
                  background: '#fbfbfb',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CubeLoader />
              </div>
            ) : (
              <Navbar activedApp={ActivedApp} />
            )}
          </UserInfoProvider>
        </ApolloProvider>
      </ThemeProvider>
    </StylesProvider>
  )
}
