import { gql, useQuery } from "@apollo/client";
import { styled, Typography, useTheme } from "@material-ui/core";
import { useUserInfo } from "@nke/spp-components.context.user-info";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ChartCard from "./chart-card";

const TextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  minWidth: "200px",
  borderRight: "1px solid white",
}));

const TypographyText = styled(Typography)(({ theme }) => ({
  display: "inline-block",
  width: "100%",
  lineHeight: "1.35",
  textAlign: "center",
  fontWeight: "inherit",
}));

interface IDashboard {
  id: number;
  customerId: number;
  key: string;
  current: number;
  limit: number;
}

interface IDashboardQuery {
  customerQuery: {
    dashboard: IDashboard[];
  };
}

interface IDashboardReport {
  storageCurrent: number;
  storageLimit: number;
  translationsCurrent: number;
  translationsLimit: number;
  usersCurrent: number;
  usersLimit: number;
}

const DASHBOARD = gql`
  query {
    customerQuery {
      dashboard {
        current
        customerId
        id
        key
        limit
      }
    }
  }
`;

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState<IDashboard[]>([]);
  const [dashboard, setDashboard] = useState<IDashboardReport>({
    storageCurrent: 0,
    storageLimit: 1,
    translationsCurrent: 0,
    translationsLimit: 1,
    usersCurrent: 0,
    usersLimit: 1,
  });

  const userInfo = useUserInfo();
  const { t } = useTranslation();
  const theme = useTheme();

  const { loading } = useQuery<IDashboardQuery>(DASHBOARD, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setDashboardData(data?.customerQuery.dashboard ?? []);
    },
  });

  useEffect(() => {
    if (dashboardData.length > 0) {
      const storage = dashboardData.filter((f) => f.key === "storage")[0];
      const translations = dashboardData.filter(
        (f) => f.key === "translations"
      )[0];
      const users = dashboardData.filter((f) => f.key === "users")[0];

      setDashboard({
        storageCurrent: storage?.current,
        storageLimit: storage?.limit > 0 ? storage?.limit : storage?.current,
        translationsCurrent: translations?.current,
        translationsLimit:
          translations?.limit > 0 ? translations?.limit : translations?.current,
        usersCurrent: users?.current,
        usersLimit: users?.limit > 0 ? users?.limit : users?.current,
      });
    }
  }, [dashboardData]);

  useEffect(() => {
    let _region = localStorage.getItem("i18nextLng");
    if (!_region) _region = userInfo?.region ?? "en";

    moment.locale(_region);
  }, [userInfo]);

  let _expDateText = "-";
  let _styleDate = {};

  if (userInfo.expirationDate) {
    const _expDate = moment(
      userInfo.expirationDate,
      "DD/MM/YYYY HH:mm:ss",
      true
    );
    _expDateText = _expDate.format("L");

    if (_expDate.isBefore(moment())) {
      _styleDate = {
        color: theme.palette.error.main,
        fontWeight: "bold",
      };
    } else if (_expDate.isBefore(moment().add(1, "month"))) {
      _styleDate = {
        color: theme.palette.secondary.main,
      };
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <ChartCard
        title={t("storage")}
        max={dashboard.storageLimit}
        current={dashboard.storageCurrent}
        loading={loading}
        um="GB"
      />
      <ChartCard
        title={t("translations")}
        max={dashboard.translationsLimit}
        current={dashboard.translationsCurrent}
        loading={loading}
      />
      <ChartCard
        title={t("users")}
        max={dashboard.usersLimit}
        current={dashboard.usersCurrent}
        loading={loading}
      />
      <TextContainer style={_styleDate}>
        <TypographyText>{t("expData")}</TypographyText>
        <TypographyText>{_expDateText}</TypographyText>
      </TextContainer>
    </div>
  );
};

export default Dashboard;
