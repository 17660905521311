import React from "react";
import { makeStyles, Typography, useTheme } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import LiquidFillGauge from "react-liquid-gauge";
import { color } from "d3-color";

const useStyles = makeStyles((theme) => ({
  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "calc(100% - 60px)",
  },
  text: {
    display: "inline-block",
    width: "100%",
    lineHeight: "1.35",
    textAlign: "end",
    paddingRight: theme.spacing(1.5),
  },
  textUm: {
    fontSize: ".8rem",
    marginLeft: "3px",
    display: "inline-block",
    alignSelf: "flex-end",
  },
  skeleton: {
    marginLeft: "auto",
  },
  cardContainer: {
    display: "flex",
    padding: `0 ${theme.spacing(2)}px`,
    minWidth: "200px",
    borderRight: "1px solid",
  },
}));

const ChartCard = (props) => {
  const { title, max, current, um, loading } = props;
  const classes = useStyles();
  const theme = useTheme();

  const radius = 30;
  const percentage = (current * 100) / max;
  const fillColor =
    percentage <= 40
      ? "#ffffff"
      : percentage <= 80
      ? theme.palette.secondary.main
      : theme.palette.error.main;
  const fontWeight = percentage <= 80 ? 400 : 700;

  return (
    <div className={classes.cardContainer}>
      <LiquidFillGauge
        width={radius * 2}
        height={radius * 2}
        value={percentage}
        percent="%"
        textSize={1}
        textOffsetX={0}
        textOffsetY={4}
        waveAnimation
        waveFrequency={1}
        waveAmplitude={5}
        gradient
        circleStyle={{
          fill: fillColor,
        }}
        waveStyle={{
          fill: color(fillColor).toString(),
        }}
        textStyle={{
          fill: color(fillColor).toString(),
          fontFamily: "Roboto",
          fontWeight,
        }}
        waveTextStyle={{
          fill: color("#114F8E").toString(),
          fontFamily: "Roboto",
          fontWeight,
        }}
        outerRadius={0.6}
        innerRadius={0.56}
      />
      <div className={classes.textContainer}>
        <Typography
          className={classes.text}
          variant="subtitle1"
          style={{ fontSize: ".9rem", color: fillColor, fontWeight }}
        >
          {loading ? (
            <Skeleton
              className={classes.skeleton}
              animation="wave"
              width="80%"
            />
          ) : (
            title
          )}
        </Typography>
        <Typography
          className={classes.text}
          variant="h5"
          style={{ fontSize: "1.15rem", color: fillColor, fontWeight }}
        >
          {loading ? (
            <Skeleton
              className={classes.skeleton}
              animation="wave"
              width="60%"
            />
          ) : (
            <>
              {current} / {max}
              {um ? <span className={classes.textUm}>{um}</span> : null}
            </>
          )}
        </Typography>
      </div>
    </div>
  );
};

export default ChartCard;
