export default {
  en: {
    translation: {
      backHome: "Back to home",
      backToPlatform: "Back to platform",
      cart: "Cart",
      expData: "Expiration date",
      goBack: "Go back",
      loading: "LOADING",
      reportBug: "Report a bug",
      settings: "Settings",
      storage: "Storage",
      translations: "Translations",
      userMenu: "User menu",
      users: "Users",
    },
  },
  it: {
    translation: {
      backHome: "Torna alla home",
      backToPlatform: "Ritorna a platform",
      cart: "Carrello",
      expData: "Data di scadenza",
      goBack: "Torna indietro",
      loading: "CARICAMENTO",
      reportBug: "Segnala un bug",
      settings: "Impostazioni",
      storage: "Spazio utilizzato",
      translations: "Trasformazioni",
      userMenu: "Menu utente",
      users: "Utenti",
    },
  },
  es: {
    translation: {
      backHome: "inicio",
      cart: "Carro",
      goBack: "Regresa",
      settings: "Configuracion",
      userMenu: "Menú del Usuario",
    },
  },
};
